<template>
  <div class="mb-4 relative w-full overflow-x-hidden">
    <input
      v-if="type !== 'textarea'"
      class="
        z-5
        input
        border-2 border-gray-200
        appearance-none
        w-full
        px-3
        py-3
        pt-5
        pb-2
        focus
        font-cl
        text-gray-500
        focus:border-cl-1
        focus:outline-none
        active:outline-none
        active:border-cl-1
      "
      :type="type"
      v-model="value"
    />
    <textarea
      v-else
      v-model="value"
      class="
        z-5
        input
        border-2 border-gray-200
        w-full
        px-3
        py-3
        pt-5
        pb-2
        focus
        font-cl
        text-gray-500
        focus:border-cl-1
        focus:outline-none
        active:outline-none
        active:border-cl-1
        overflow-x-hidden
        resize-none
      "
    ></textarea>
    <label
      for="password"
      class="
        label
        z-0
        select-none
        absolute
        mb-0
        -mt-2
        pt-4
        pl-3
        leading-tighter
        text-gray-400 text-base
        cursor-text
        font-cl
        uppercase
      "
      :class="{ label2: value.length > 0 }"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
  props: {
    modelValue: { default: '' },
    label: {},
    type: {
      default: 'text'
    }
  },
  setup (props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    return { value }

  }
}
</script>

<style scoped>
textarea {
  display: inline-block;
  height: 200px;
}
.input {
  transition: border 0.2s ease-in-out;
  min-width: 280px;
}

.input:focus + .label,
.input:active + .label,
.input.filled + .label {
  font-size: 0.75rem;
  transition: all 0.2s ease-out;
  top: -0.1rem;
  color: #2f2ff2;
}

.label2 {
  font-size: 0.75rem;
  transition: all 0.2s ease-out;
  top: -0.1rem !important;
  color: #2f2ff2;
}

.label {
  transition: all 0.2s ease-out;
  top: 0.4rem;
  left: 0;
}
</style>