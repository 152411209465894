<template>
  <button
    class="
      py-4
      px-20
      text-sm
      border-2 border-cl-2
      text-cl-2
      hover:bg-green-50
      focus:outline-none
      uppercase
    "
  >
    <slot></slot>
  </button>
</template>

<script>
export default {

}
</script>

<style>
</style>