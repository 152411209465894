<template>
  <div
    class="p-6 w-full md:w-auto md:p-16 flex flex-col font-cl justify-center"
  >
    <div class="flex flex-row items-center justify-between mb-4">
      <h1 class="text-2xl font-black text-cl-1 font-cl uppercase">
        Get in touch
      </h1>
      <button @click="$emit('closeForm')" class="ml-2 text-2xl text-gray-300">
        X
      </button>
    </div>
    <Input label="Name" v-model="form.name"></Input>
    <Input label="Email" v-model="form.email"></Input>
    <Input label="Phone" v-model="form.phone"></Input>
    <Input label="Company Name" v-model="form.company"></Input>
    <Input label="Message" type="textarea" v-model="form.message"></Input>
    <Button @click="submitEmail"> Submit </Button>
  </div>
</template>

<script>
import Input from './Input.vue'
import Button from './Button.vue'
import { ref } from 'vue'
import { createToast } from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'
import axios from 'axios'


export default {
  components: { Button, Input },
  setup (props, { emit }) {
    const form = ref({
      name: '',
      email: '',
      phone: '',
      company: '',
      message: ''
    })

    function submitEmail () {
      emit('closeForm')
      const params = {
        name: form.value.name,
        email: form.value.email,
        phone: form.value.phone,
        company: form.value.company,
        message: form.value.message
      }
      axios.get('https://c-learnin.pt/email.php', { params }).then(resp => console.log(resp))
      createToast('Email enviado...',
        {
          type: 'default',
          toastBackgroundColor: '#2f2ff2',
          position: 'bottom-right',
        })
    }

    return { form, submitEmail }
  }

}
</script>
