<template>
  <div class="w-full h-screen flex flex-wrap">
    <!-- Left -->
    <div
      class="flex flex-col bg-cl-1 w-full md:w-1/2 lg:w-2/3 h-1/5 md:h-screen"
    >
      <div
        class="
          p-1
          md:p-4
          flex-grow
          h-full
          flex
          items-center
          justify-center
          text-white
        "
      >
        <img class="h-1/2 md:h-60 md:w-auto" src="./assets/logo.svg" />
      </div>
      <div class="mt-10 h-20 px-10 hidden md:flex flex-row items-center">
        <img class="text-white" src="./assets/logo2.svg" />
        <div class="flex-grow flex justify-end">
          <span class="text-white text-xs"
            >C-LEARNIN' 2021, All rights reserved.</span
          >
        </div>
      </div>
    </div>
    <!-- Right -->
    <div
      class="
        w-full
        md:w-1/2
        lg:w-1/3
        md:h-screen
        flex
        items-start
        md:items-center
        justify-center
      "
    >
      <div
        v-if="!form"
        class="
          p-6
          w-full
          md:w-auto md:p-16
          flex flex-col
          font-cl
          justify-center
        "
      >
        <span
          class="
            text-center
            md:text-left
            uppercase
            text-2xl text-cl-1
            font-bold
          "
        >
          Coming soon
        </span>
        <span class="text-cl-3 mb-10 mt-4 text-justify">
          <p>
            Managers must be expedient. This means being constantly up-to-date,
            keeping stakeholders engaged and searching out of the box for new
            ways. Therefore, nurture growth must be more than important, it has
            to be a priority and scalable to each agenda.
          </p>
          <p class="mt-2">
            C-Learnin, the tailored management toolkit to embrace the 21st
            century challenging realities
          </p>
        </span>
        <div class="text-center md:text-left">
          <Button @click="form = true"> GET IN TOUCH </Button>
        </div>
        <span class="text-cl-3 mt-10 text-center md:text-left">
          <a href="mailto:pm@c-learnin.pt" class="hover:text-cl-2"
            >pm@c-learnin.pt</a
          >
        </span>
      </div>
      <MailForm v-if="form" v-on:close-form="form = false"> </MailForm>
    </div>
  </div>
</template>

<script>
import Button from './components/Button.vue'
import MailForm from './components/MailForm.vue'
import { ref } from 'vue'

export default {
  name: 'App',
  components: {
    Button,
    MailForm
  },
  setup () {
    const form = ref(false)

    return { form }

  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;600;700&display=swap");

.font-cl {
  font-family: "Space Grotesk", sans-serif;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
